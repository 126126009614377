<template>
  <div style="overflow-x: hidden">
    <div
      v-if="selectedComponent"
      :class="{
        login: true,
        loginFootball: getBackgroundClass === 'loginFootball',
        loginBaseball: getBackgroundClass === 'loginBaseball',
        loginBasketball: getBackgroundClass === 'loginBasketball',
        loginSoccer: getBackgroundClass === 'loginSoccer',
      }"
    >
      <loginComponent />
    </div>
    <EntryPoint v-else></EntryPoint>
  </div>
</template>

<script>
import loginComponent from "../components/loginComponent";
import EntryPoint from "../../websites/views/EntryPoint.vue";

export default {
  components: {
    loginComponent,
    EntryPoint,
  },
  computed: {
    getBackgroundClass() {
      const classes = [
        "loginFootball",
        "loginBaseball",
        "loginBasketball",
        "loginSoccer",
      ];
      return classes[Math.floor(Math.random() * classes.length)];
    },
    selectedComponent() {
      // Extract the subdomain from the current URL
      const hostname = window.location.hostname;
      const parts = hostname.split(".");
      console.log(parts.length);
      console.log(parts[0]);
      if (
        parts.length <= 1 ||
        parts[0] === "app" ||
        parts[0] === "testapp" ||
        parts[0] === "demo"
      ) {
        return true;
      } else {
        // For all other subdomains, display the SpecialSubdomain component
        return false;
      }
    },
  },
  mounted() {},
};
</script>
