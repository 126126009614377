<template>
  <div
    class="modal fade"
    id="viewAvailability"
    tabindex="-1"
    aria-labelledby="viewAvailabilityLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="viewAvailabilityLabel">
            View Availability
          </h5>

          <button
            type="button"
            class="btn-close"
            id="viewAvailabilityClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h5>Date: {{ getAvailabilityDate }}</h5>
          <div class="col" style="margin-top: 5px">
            <label class="form-label" for="form6Example3">Start Time</label>
            <input
              v-model="availabilitySetting.startTime"
              required
              id="form6Example1"
              class="form-control mb-3"
              type="time"
            />
          </div>
          <div class="col" style="margin-top: 5px">
            <label class="form-label" for="form6Example3">End Time</label>
            <input
              v-model="availabilitySetting.endTime"
              required
              id="form6Example1"
              class="form-control mb-3"
              type="time"
            />
          </div>
          <label class="form-label" for="form6Example3"
            >Events You're Available For</label
          >

          <multiselect
            v-model="selectedCampsFormattedForMultiSelect"
            placeholder="Events"
            label="name"
            track-by="code"
            :options="getSessionOptions"
            :multiple="true"
          ></multiselect>
          <div class="form-check d-flex mb-4" style="margin-top: 20px">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="availabilitySetting.repeating"
            />
            <label class="form-check-label v-step-8"> Is Recurring</label>
          </div>
          <div v-if="availabilitySetting.repeating">
            <ul class="list-group">
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="DAY"
                  v-model="availabilitySetting.repeatingSetting"
                />
                Every Day
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="WEEK"
                  checked
                  v-model="availabilitySetting.repeatingSetting"
                />
                Every Week
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="MONTH"
                  checked
                  v-model="availabilitySetting.repeatingSetting"
                />
                Every Month
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteAvailability()"
          >
            Delete Availability
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="updateAvailability()"
          >
            Update Availability
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import swal2 from "sweetalert2";
import axios from "axios";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      dayApplicable: "TODAY",
    };
  },
  components: { Multiselect },
  props: {
    availabilitySetting: Object,
    sessionSupportedEvents: Array,
    userID: String,
    calendarID: String,
    currentAvailabilityClicked: Object,
    selectedCampsFormattedForMultiSelect: Array,
  },
  methods: {
    async deleteAvailability() {
      let inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            TODAY: "Today",
            TODAYANDFOLLOWING: "Today and Following",
            EVERYDAY: "Every Day",
          });
        }, 500);
      });

      if (!this.availabilitySetting.repeating) {
        inputOptions = new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              TODAY: "Today",
            });
          }, 500);
        });
      }

      const { value: period } = await swal2.fire({
        title: "On which days would you like to delete this event?",
        showCancelButton: true,
        input: "radio",
        inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "Please select an option";
          }
        },
      });
      if (period) {
        swal("Loading...", "", "info");
        this.availabilitySetting.date = this.currentAvailabilityClicked.start;

        let url = process.env.VUE_APP_DB_URL + "scheduling/deleteAvailability";
        axios
          .post(
            url,
            {
              alteredAvailability: this.availabilitySetting,
              periodToDelete: period,
              userID: this.userID,
              calendarID: this.calendarID,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            }
          )
          .then((response) => {
            this.$emit("changedAvailability", {
              allAvailability: response.data.availability,
            });
            document.getElementById("viewAvailabilityClose").click();
            swal("Success", "Availability has been Deleted", "success");
          })
          .catch(function (err) {
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },

    async updateAvailability() {
      if (
        !this.availabilitySetting.startTime ||
        !this.availabilitySetting.endTime ||
        !this.availabilitySetting.selectedCamps
      ) {
        swal(
          "Details Incomplete",
          "Please fill out all form fields and try again",
          "error"
        );
        return;
      }

      let inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            TODAY: "Today",
            TODAYANDFOLLOWING: "Today and Following",
            EVERYDAY: "Every Day",
          });
        }, 500);
      });

      if (!this.availabilitySetting.repeating) {
        inputOptions = new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              TODAY: "Today",
            });
          }, 500);
        });
      }
      const { value: period } = await swal2.fire({
        title: "On which days would you like to update this event?",
        showCancelButton: true,
        input: "radio",
        inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "Please select an option";
          }
        },
      });
      if (period) {
        swal("Loading...", "", "info");
        this.availabilitySetting.date = this.currentAvailabilityClicked.start;
        this.availabilitySetting.selectedCamps =
          this.selectedCampsFormattedForMultiSelect;
        let url = process.env.VUE_APP_DB_URL + "scheduling/updateAvailability";
        axios
          .post(
            url,
            {
              alteredAvailability: this.availabilitySetting,
              periodToUpdate: period,
              userID: this.userID,
              calendarID: this.calendarID,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            }
          )
          .then((response) => {
            this.$emit("changedAvailability", {
              allAvailability: response.data.availability,
            });
            document.getElementById("viewAvailabilityClose").click();
            swal("Success", "Availability has been Set", "success");
          })
          .catch(function (err) {
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },
  },
  computed: {
    getAvailabilityDate() {
      return new Date(
        this.currentAvailabilityClicked.start
      ).toLocaleDateString();
    },
    getSessionOptions() {
      let options = [];
      for (const event of this.sessionSupportedEvents) {
        options.push({
          name: event.campName,
          code: event.campStripeId,
        });
      }
      return options;
    },
  },

  mounted() {
    if (this.currentAvailabilityClicked.id) {
      this.getSelectedAvailability();
    }
  },
};
</script>

<style scoped></style>
