var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper container justify-content-center",attrs:{"id":"app"}},[_c('ClientLayout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"container justify-content-center"},[_c('div',{staticClass:"container mt-5 justify-content-center"}),_c('div',{staticClass:"row text-center v-step-3",staticStyle:{"margin-top":"35px"}},[_c('div',{staticClass:"card-form__inner col-md-1",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
          padding: '10px',
          marginBottom: '10px',
        })},[_c('h4',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(_vm.getCredits))]),_c('img',{attrs:{"src":require("../../../../public/SaCoin.png"),"height":"29"}})]),_c('div',{staticClass:"col-md-9"},[_c('b',[_c('h1',{style:({
              marginLeft: _vm.isMobile() ? '21px' : '',
              textAlign: 'center',
            })},[_vm._v(" Ad Credit Store"),_c('i',{staticClass:"fa-solid fa-circle-info fa-sm",staticStyle:{"color":"#45487a","cursor":"pointer","margin-left":"3px"},on:{"click":_vm.adCreditStoreExplanation}})])])]),_c('div',{staticClass:"col-sm-2"},[_c('button',{staticClass:"btn rounded-pill",staticStyle:{"background-color":"#363166","color":"white","float":"right"},on:{"click":function($event){return _vm.purchaseAds()}}},[_vm._v(" Purchase Ads ")])]),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.creditPackages),function(pack,index){return _c('ul',{key:pack._id,staticClass:"list-group"},[(index % 3 == 0)?_c('CreditPackageCard',{staticClass:"grow",attrs:{"id":pack._id,"price":pack.price,"image":pack.image,"title":pack.title,"tokens":pack.tokens},on:{"updateBalance":_vm.updateCredits}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.creditPackages),function(pack,index){return _c('ul',{key:pack._id,staticClass:"list-group"},[(index % 3 == 1)?_c('CreditPackageCard',{staticClass:"grow",attrs:{"id":pack._id,"price":pack.price,"image":pack.image,"title":pack.title,"tokens":pack.tokens},on:{"updateBalance":_vm.updateCredits}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.creditPackages),function(pack,index){return _c('ul',{key:pack._id,staticClass:"list-group"},[(index % 3 == 2)?_c('CreditPackageCard',{staticClass:"grow",attrs:{"id":pack._id,"price":pack.price,"image":pack.image,"title":pack.title,"tokens":pack.tokens},on:{"updateBalance":_vm.updateCredits}}):_vm._e()],1)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }