import { render, staticRenderFns } from "./RecruitReferral.vue?vue&type=template&id=24a8da38&scoped=true&"
import script from "./RecruitReferral.vue?vue&type=script&lang=js&"
export * from "./RecruitReferral.vue?vue&type=script&lang=js&"
import style0 from "./RecruitReferral.vue?vue&type=style&index=0&id=24a8da38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a8da38",
  null
  
)

export default component.exports