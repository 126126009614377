var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper container justify-content-center",attrs:{"id":"app"}},[_c('Layout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"container justify-content-center"},[_c('div',{staticClass:"container mt-5 justify-content-center"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-5 col-sm-5"},[_c('div',{staticClass:"card-form",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"card-form__inner",style:({
                width: _vm.isMobile ? '99%' : '',
                marginLeft: _vm.isMobile ? '21px' : '',
                textAlign: _vm.isMobile ? 'center' : '',
                marginTop: _vm.isMobile ? '21px' : '',
              })},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('hr'),_c('h1',{staticClass:"text-center"},[_vm._v("QR Code")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6"},[_c('qrcode-vue',{staticClass:"center",attrs:{"value":_vm.getQRLink,"size":_vm.getQRSize,"level":"H"}})],1)]),_c('hr'),_c('div',{staticClass:"row center justify-content-center"},[_c('center',[_c('h5',[_c('a',{staticClass:"link",staticStyle:{"color":"#0d6efd","text-underline":"#0d6efd"},attrs:{"href":_vm.getQRLink,"target":"_blank"}},[_vm._v(" Personal Athlete Profile Link")])])])],1),_c('hr'),_c('div',{staticClass:"row justify-content-center"},[_c('h5',{staticClass:"text-center mb-3"},[_vm._v(" Share Your Talent! "),_c('a',{staticStyle:{"all":"unset","cursor":"pointer","text-decoration":"none"},attrs:{"href":_vm.getTwitterShareLink,"target":"_blank"}},[_c('i',{staticClass:"fa-brands fa-square-x-twitter fa-xl"})])])])])])])]),_c('div',{staticClass:"col-md-5 col-sm-5 justify-content-center"},[_c('div',{staticClass:"card-form v-step-0",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"card-form__inner",style:({
                width: _vm.isMobile ? '99%' : '',
                marginLeft: _vm.isMobile ? '21px' : '',
                textAlign: _vm.isMobile ? 'center' : '',
              })},[_c('div',[_vm._m(1),_c('hr'),_c('br'),_c('h4',{staticClass:"card-text"},[_vm._v("Total Views: "+_vm._s(_vm.getTotalViews))]),_c('hr'),_c('h4',{staticClass:"card-text"},[_vm._v(" Views this Month: "+_vm._s(_vm.getTotalViews)+" ")])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"card-title text-center"},[_c('b',[_vm._v("My Athlete Profile")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"card-title"},[_c('b',[_vm._v("Profile Views ")])])}]

export { render, staticRenderFns }