<template>
  <div>
    <!-- start of if statements to determine proper type of input-->

    <!--height type-->
    <div v-if="this.field.fieldType == 'height'">
      <label for="cardName" class="card-input__label">{{
        field.fieldName
      }}</label>
      <div class="row">
        <div class="card-input col">
          <select
            class="card-input__input -select"
            v-model="obj.fieldValue"
            
            required
          >
            <option disabled selected value="">Feet</option>
            <option value="0" class="option">0</option>
            <option value="1" class="option">1</option>
            <option value="2" class="option">2</option>
            <option value="3" class="option">3</option>
            <option value="4" class="option">4</option>
            <option value="5" class="option">5</option>
            <option value="6" class="option">6</option>
            <option value="7" class="option">7</option>
            <option value="8" class="option">8</option>
          </select>
        </div>
        <div class="card-input col">
          <select
            class="card-input__input -select"
            v-model="obj.value2"
            
            required
          >
            <option disabled selected value="">Inches</option>
            <option value="0" class="option">0</option>
            <option value="1" class="option">1</option>
            <option value="2" class="option">2</option>
            <option value="3" class="option">3</option>
            <option value="4" class="option">4</option>
            <option value="5" class="option">5</option>
            <option value="6" class="option">6</option>
            <option value="7" class="option">7</option>
            <option value="8" class="option">8</option>
            <option value="9" class="option">9</option>
            <option value="10" class="option">10</option>
            <option value="11" class="option">11</option>
          </select>
        </div>
      </div>
    </div>
    <!--email input type-->
    <div v-if="this.field.fieldType == 'email'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            field.fieldName
          }}</label>
          <input
            type="email"
            placeholder="Email"
            id="cardName"
            class="card-input__input"
            v-model="obj.fieldValue"
            
            required
          />
        </div>
      </div>
    </div>
    <!--Phone Number Type-->
    <div v-if="this.field.fieldType == 'phoneNumber'">
      <div class="card-input">
        <label for="phoneNumber" class="card-input__label">{{
          field.fieldName
        }}</label>
        <input
          type="text"
          id="phoneNumber"
          class="card-input__input"
          placeholder="Phone Number"
          v-model="obj.fieldValue"
          
          required
        />
      </div>
    </div>
    <!--Text-short Input-->
    <div v-if="this.field.fieldType == 'textShort'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            field.fieldName
          }}</label>
          <input
            type="text"
            id="cardName"
            class="card-input__input"
            v-model="obj.fieldValue"
            
            required
          />
        </div>
      </div>
    </div>
    <!--Text-Long Input-->
    <div v-if="this.field.fieldType == 'textLong'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            field.fieldName
          }}</label>
          <textarea
            type="text"
            id="cardName"
            class="card-input__input"
            rows="4"
            cols="50"
            style="height: 120px"
            v-model="obj.fieldValue"
            
            required
          ></textarea>
        </div>
      </div>
    </div>
    <!--Phone Number Type-->
    <div v-if="this.field.fieldType == 'number'">
      <div class="card-input">
        <label for="phoneNumber" class="card-input__label">{{
          field.fieldName
        }}</label>
        <input
          type="number"
          id="Number"
          class="card-input__input"
          placeholder="Number"
          v-model="obj.fieldValue"
          
          required
        />
      </div>
    </div>
    <!--yesNo Type-->
    <div v-if="this.field.fieldType == 'yesNo'">
      <div class="col">
        <div class="card-input">
          <label for="phoneNumber" class="card-input__label">{{
            field.fieldName
          }}</label>
          <select
            class="card-input__input -select"
            style="background-position: 95% center"
            v-model="obj.fieldValue"
            
            required
          >
            <option disabled selected value="">Yes/No</option>
            <option value="Yes" class="option">Yes</option>
            <option value="No" class="option">No</option>
          </select>
        </div>
      </div>
    </div>

      <div v-if="this.field.fieldType == 'dropdown'">
          <div class="col">
              <div class="card-input">
                  <label for="phoneNumber" class="card-input__label">{{
                      field.fieldName
                      }}</label>
                  <select
                          class="card-input__input -select"
                          style="background-position: 95% center"
                          v-model="obj.fieldValue"

                          required
                  >
                      <option :value="obj.fieldValue" selected>{{ obj.fieldValue }}</option>
                  </select>
              </div>
          </div>
      </div>
    
  </div>
</template>
<script>


export default {
  components: {},
  props: {
    field: Object,
  },
  data() {
    return {
      obj:{
      },
      
    };
  },
  methods: {
  
    formatData()
    {
      this.obj = this.field;
    if(this.obj.fieldValue != null)
    {
    if(this.obj.fieldValue.includes("'")&& this.obj.fieldValue.length < 5)
    {
      let temp = this.field.fieldValue.split("'");
      this.obj.value2= temp[1];
      this.obj.fieldValue = temp[0];
      
    }}
    }
  },

  created() {
    this.formatData();
    
  },
};
</script>
<style>
</style>
<style scoped>
