var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",style:({ minHeight: _vm.isMobile ? '1500px' : '1000px' })},[_c('div',{staticClass:"row"},[_c('div',{class:{ 'col-md-6': true, 'mt-5': _vm.isMobile }},[_c('MetricPortfolioCard',{attrs:{"primaryColor":_vm.primaryColor,"secondaryColor":_vm.secondaryColor,"isMobile":_vm.isMobile,"photo":_vm.metricsPage.photoOne,"subheading":_vm.metricsPage.subheadingOne,"athleticInfo":_vm.recruitProfile.athleticInfo,"state":_vm.recruitProfile.state}})],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card full-metric-card dark blue"},[_c('div',{staticClass:"card-body",staticStyle:{"color":"white"}},[_c('h5',{staticClass:"card-title"},[_vm._v("Academics")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"progress",staticStyle:{"height":"30px"}},[_c('div',{staticClass:"progress-bar",style:({
                      width: (((this.gpa / 4.0) * 100) + "%"),
                      backgroundColor: this.primaryColor,
                    })},[_vm._v(" "+_vm._s(this.gpa.toFixed(2))+" ")])])]),_c('div',{staticClass:"col-md-2"},[_c('h3',[_vm._v(_vm._s(this.growthGPA.toFixed(2)))])])]),_c('br'),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"progress",staticStyle:{"height":"30px"}},[_c('div',{staticClass:"progress-bar",style:({
                      width: (((this.act / 36) * 100) + "%"),
                      backgroundColor: this.primaryColor,
                    })},[_vm._v(" "+_vm._s(this.act.toFixed(0))+" ")])])]),_c('div',{staticClass:"col-md-2"},[_c('h3',[_vm._v(_vm._s(this.growthACT.toFixed(0)))])])]),_c('br'),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"progress",staticStyle:{"height":"30px"}},[_c('div',{staticClass:"progress-bar",style:({
                      width: (((this.sat / 1600) * 100) + "%"),
                      backgroundColor: this.primaryColor,
                    })},[_vm._v(" "+_vm._s(this.sat.toFixed(0))+" ")])])]),_c('div',{staticClass:"col-md-2"},[_c('h3',[_vm._v(_vm._s(this.growthSAT.toFixed(0)))])])])])])])])]),_c('div',{staticClass:"row justify-content-center mt-5",style:({
      overflowY: _vm.isMobile ? 'hidden' : '',
      maxHeight: _vm.isMobile ? '350px' : '',
    })},[_c('MetricBanner',{attrs:{"primaryColor":this.primaryColor,"secondaryColor":this.secondaryColor,"state":_vm.recruitProfile.state,"academicInfo":_vm.recruitProfile.academicInfo,"athleticInfo":_vm.recruitProfile.athleticInfo}})],1),_c('div',{style:({ marginBottom: _vm.isMobile ? '500px' : '' })})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-2"},[_c('h3',[_vm._v("GPA")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-2"},[_c('h3',[_vm._v("ACT")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-2"},[_c('h3',[_vm._v("SAT")])])}]

export { render, staticRenderFns }