var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"20px","max-width":"90%"}},[_c('div',{style:({ display: 'flex', alignItems: 'center', marginBottom: '20px' })},[_c('img',{style:({
        borderRadius: '50%',
        width: '150px',
        height: '150px',
        objectFit: 'cover',
        marginRight: '20px',
      }),attrs:{"src":_vm.getImage}}),_c('div',{style:({ display: 'flex', flexDirection: 'column', flexGrow: 1 })},[_c('h1',{style:({ margin: '0', color: '#343a40', textAlign: 'right' })},[_vm._v(" "+_vm._s(_vm.recruitProfile.firstName)+" "+_vm._s(_vm.recruitProfile.lastName)+" ")])])]),_c('div',{staticClass:"info-section"},[_c('h3',{style:({
        borderBottom: '2px solid #343a40',
        paddingBottom: '10px',
        marginBottom: '20px',
        color: '#343a40',
      })},[_vm._v(" Personal Information ")]),_c('div',{staticClass:"container"},[(_vm.recruitProfile.school)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("School:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.school))])])]):_vm._e(),(_vm.recruitProfile.state)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("State:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.state))])])]):_vm._e(),(_vm.recruitProfile.class)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("Graduation Class:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.class))])])]):_vm._e(),(_vm.recruitProfile.profileLinks.twitter)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("Twitter Profile:")]),_c('a',{attrs:{"href":_vm.recruitProfile.profileLinks.twitter,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.recruitProfile.profileLinks.twitter)+" ")])])])]):_vm._e(),(_vm.recruitProfile.profileLinks.hudl)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("Hudl Profile:")]),_c('a',{attrs:{"href":_vm.recruitProfile.profileLinks.hudl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.recruitProfile.profileLinks.hudl)+" ")])])])]):_vm._e()])]),_c('div',{staticClass:"info-section",style:({ marginTop: '30px' })},[_c('h3',{style:({
        borderBottom: '2px solid #343a40',
        paddingBottom: '10px',
        marginBottom: '20px',
        color: '#343a40',
      })},[_vm._v(" Athletic Information ")]),_c('div',{staticClass:"container"},[(_vm.recruitProfile.athleticInfo.height)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("Height:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.athleticInfo.height)+" ")])])]):_vm._e(),(_vm.recruitProfile.athleticInfo.weight)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("Weight:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.athleticInfo.weight)+" ")])])]):_vm._e(),(_vm.recruitProfile.athleticInfo.primarySport)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("Primary Sport:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.athleticInfo.primarySport)+" ")])])]):_vm._e(),_vm._l((_vm.filteredAdditionalMetrics),function(metric){return _c('div',{key:metric.title,staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(metric.title)+":")]),_vm._v(" "+_vm._s(metric.value)+" ")])])])})],2)]),_c('div',{staticClass:"info-section",style:({ marginTop: '30px' })},[_c('h3',{style:({
        borderBottom: '2px solid #343a40',
        paddingBottom: '10px',
        marginBottom: '20px',
        color: '#343a40',
      })},[_vm._v(" Academic Information ")]),_c('div',{staticClass:"container"},[(_vm.recruitProfile.academicInfo.gpa)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("GPA:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.academicInfo.gpa))])])]):_vm._e(),(_vm.recruitProfile.academicInfo.act)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("ACT:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.academicInfo.act))])])]):_vm._e(),(_vm.recruitProfile.academicInfo.sat)?_c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v("SAT:")]),_vm._v(" "+_vm._s(_vm.recruitProfile.academicInfo.sat))])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }