<script setup></script>

<template>
  <div
    class="information-card"
    v-bind:style="{
      maxWidth: isMobile ? '90%' : '275px',
      cursor: 'pointer',
    }"
  >
    <h5 class="card-title text-start">
      <span class="badge actionFeature">{{
        subscription.subscriptionName
      }}</span>
    </h5>
    <br />

    <h5 class="card-body">
      <div v-if="this.promo && !this.yearly">
        <label v-if="promo" style="margin-left: 33px; font-size: 15px">
          <s class="original-price">${{ getMonthly }} / month</s>
        </label>
        <br />
        <span class="promotional-price"> $0.00 For First 7 Days </span>
      </div>
      <div v-else>
        <label v-if="this.yearly" style="margin-left: 33px; font-size: 15px"
          ><s>${{ getFakeYearly }}</s></label
        ><br v-if="this.yearly" />
        <span
          ><i class="fa-solid fa-medal fa-xl m-1" style="color: #363166"></i>
          <label style="margin-right: 3px; cursor: pointer">
            ${{
              this.yearly ? getYearly + " / year" : getMonthly + " / month"
            }}</label
          >
        </span>
      </div>
    </h5>
    <p>Creating Your Events, Plus...</p>
    <br />
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      Customer Checkout Fee ~
      {{ (subscription.customerUpchargePercentage - 3).toFixed(1) }}%
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      AI Generated Website <span class="badge actionFeature">New!</span>
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      Personalized Branding
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      Download Customer Data
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      Unlimited Mass Emailing
    </p>
    <button
      class="card-form__button grow"
      id="regularSignInBtn"
      @click="buySubscription(subscription)"
    >
      {{ this.getButtonText }}
    </button>
  </div>
</template>

<script>
import axios from "axios";
var numeral = require("numeral");

export default {
  props: {
    promo: {
      type: Boolean,
      default: false,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: "",
        subscriptionTier: 0,
      },
    };
  },
  methods: {
    buySubscription(plan) {
      this.$emit("buySubscription", plan);
    },
    getSubscription() {
      //get rookie subscription via axios post request with auth
      let url =
        process.env.VUE_APP_DB_URL +
        "subscription/getSubscriptionByName?name=Pro";
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.subscription = response.data.subscription;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format("0,0.00");
    },

    getYearly() {
      return numeral(this.subscription.yearlyPrice).format("0,0.00");
    },

    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format("0,0.00");
    },
    getButtonText() {
      if (this.promo && !this.yearly) {
        return "Get Started For Free!";
      }
      return "Get Started Today!";
    },
  },

  mounted() {
    this.getSubscription();
  },
};
</script>
<style scoped>
.actionFeature {
  background-color: #a39ed0;
}
.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}
.original-price {
  text-decoration: line-through;
  color: #999;
}
.promotional-price {
  font-weight: bold;
  color: #4caf50;
}
</style>
