var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"external",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"horizontal-scroll-wrapper",style:({ overflowX: 'hidden', height: _vm.isMobile ? '200vw' : '125vw' })},[_vm._l((this.videos),function(video,index){return _c('div',{key:video._id,class:{
        'img-wrapper': true,
        slower: index % 3 === 0,
        faster: index % 3 === 1,
        'slower vertical': index % 3 === 2,
      }},[_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"rel":"noopener","data-bs-toggle":"modal","data-bs-target":"#ShowVideoModal"},on:{"click":function($event){_vm.selectedVideo = video.url}}},[_c('video',{staticStyle:{"max-height":"300px","max-width":"575px"},attrs:{"src":video.url,"controls":""}})])])}),_vm._l((this.photos),function(photo,index){return _c('div',{key:photo._id,class:{
        'img-wrapper': true,
        slower: index % 3 === 0,
        faster: index % 3 === 1,
        'slower vertical': index % 3 === 2,
      }},[_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"rel":"noopener","data-bs-toggle":"modal","data-bs-target":"#ShowPhotoModal"},on:{"click":function($event){_vm.selectedPhoto = photo.url}}},[_c('img',{attrs:{"src":photo.url,"alt":""}})])])})],2),_c('ShowPhotoModal',{attrs:{"photo":this.selectedPhoto}}),_c('ShowVideoModal',{attrs:{"video":this.selectedVideo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }