var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ container: !_vm.isMobile },attrs:{"id":"app"}},[_c('div',{staticClass:"card-form"},[_c('div',{staticClass:"card-form__inner"},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.membershipLogo}}),_c('div',{staticClass:"card-body",staticStyle:{"margin-top":"10px"}}),_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b',[_vm._v(_vm._s(this.membershipName))])])]),_c('li',{staticClass:"list-group-item"},[(!this.hidePlatformFees)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Price")]),_c('div',[_vm._v(" $"+_vm._s(this.basePriceSelected.toFixed(2))+"/"+_vm._s(this.displayPaymentLength)+" ")])]):_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Price")]),_c('div',[_vm._v("$"+_vm._s(this.getTotal)+"/"+_vm._s(this.displayPaymentLength))])]),(!this.hidePlatformFees)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Summer Athletes Platform Fee")]),_c('div',[_vm._v("$"+_vm._s(_vm.getPlatformFee.toFixed(2)))])]):_vm._e(),(!this.hidePlatformFees)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Credit Card Processing Fee")]),_c('div',[_vm._v("$"+_vm._s(_vm.getCreditFee.toFixed(2)))])]):_vm._e(),(!this.hidePlatformFees)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Total Payment")]),_c('div',[_vm._v("$"+_vm._s(_vm.getTotal))])]):_vm._e()])]),_c('div',[_c('button',{staticClass:"card-form__button rounded-pill",style:({ backgroundColor: _vm.primaryColor, color: 'white' }),on:{"click":_vm.submitPayment}},[_vm._v(" Pay $"+_vm._s(this.getTotal)+" "+_vm._s(this.displayPaymentPeriod)+" ")]),_c('br'),_c('br'),_c('br'),_c('button',{class:{
            'card-form__button': true,
            'w-50': _vm.isMobile,
            'w-25': !_vm.isMobile,
            'rounded-pill': true,
          },style:({
            backgroundColor: _vm.secondaryColor,
            color: 'white',
          }),on:{"click":_vm.back}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_vm._v(" Back ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }