<template>
  <div class="entryPointTheme">
    <div v-if="isActive === 1" v-html="html" class="entryPointTheme"></div>
    <lockout v-if="isActive === 2"></lockout>
    <StaticAd v-if="isActive === 3"></StaticAd>
    <CustomTemplateOne
      v-if="isActive === 4"
      :website="website"
    ></CustomTemplateOne>
  </div>
</template>

<script>
import axios from "axios";
import Lockout from "../components/Lockout.vue";
import StaticAd from "../components/StaticAd.vue";
import CustomTemplateOne from "../../recruits/views/AthleteProfileTemplates/CustomTemplateOne/Container.vue";
export default {
  components: {
    Lockout,
    StaticAd,
    CustomTemplateOne,
  },
  data() {
    return {
      activeStatus: 0,
      html: null,
      website: {},
    };
  },
  methods: {
    async getHtml() {
      const hostname = window.location.hostname;
      const parts = hostname.split(".");
      const subdomain = parts[0];
      let url =
        process.env.VUE_APP_DB_URL + "ai/getHtml?subDomain=" + subdomain;
      axios
        .get(url)
        .then((response) => {
          console.log(response);

          if (response.data) {
            if (response.data.newVersion) {
              if (
                response.data.website.websiteTemplateType ===
                "CustomTemplateOne"
              ) {
                this.activeStatus = 4;
                this.website = response.data.website;
              }
            } else {
              this.activeStatus = 1;
              this.html = response.data;
            }
          } else {
            this.activeStatus = 1;
            this.html = response.data;
          }
        })
        .catch((err) => {
          let error = err.response;
          console.log(error.status);
          if (error.status == 403) {
            console.log("here");
            this.activeStatus = 2;
          } else {
            this.activeStatus = 3;
          }
          //swal("Error", "Website could not be found", "error");
        });
    },
  },
  computed: {
    isActive() {
      return this.activeStatus;
    },
  },
  mounted() {},
  created() {
    this.getHtml();
  },
};
</script>
<style>
.entryPointTheme {
  background-image: url("../../../public/plain-white-background.jpg");
}
</style>
