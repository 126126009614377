<template>
  <div
    class="modal fade"
    id="addAvailability"
    tabindex="-1"
    aria-labelledby="addAvailabilityLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="addAvailabilityLabel">
            Set Availability
          </h5>

          <button
            type="button"
            class="btn-close"
            id="addAvailabilityClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h5>Date: {{ getAvailabilityDate }}</h5>
          <div class="col" style="margin-top: 5px">
            <label class="form-label" for="form6Example3">Start Time</label>
            <input
              v-model="availabilitySetting.startTime"
              required
              id="form6Example1"
              class="form-control mb-3"
              type="time"
            />
          </div>
          <div class="col" style="margin-top: 5px">
            <label class="form-label" for="form6Example3">End Time</label>
            <input
              v-model="availabilitySetting.endTime"
              required
              id="form6Example1"
              class="form-control mb-3"
              type="time"
            />
          </div>
          <label class="form-label" for="form6Example3"
            >Events You're Available For</label
          >

          <multiselect
            v-model="availabilitySetting.selectedCamps"
            placeholder="Events"
            label="name"
            track-by="code"
            :options="getSessionOptions"
            :multiple="true"
          ></multiselect>
          <div class="form-check d-flex mb-4" style="margin-top: 20px">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="availabilitySetting.repeating"
            />
            <label class="form-check-label v-step-8"> Is Recurring</label>
          </div>
          <div v-if="availabilitySetting.repeating">
            <ul class="list-group">
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="DAY"
                  v-model="availabilitySetting.repeatingSetting"
                />
                Every Day
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="WEEK"
                  checked
                  v-model="availabilitySetting.repeatingSetting"
                />
                Every Week
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="MONTH"
                  checked
                  v-model="availabilitySetting.repeatingSetting"
                />
                Every Month
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="setAvailability()"
          >
            Set Availability
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {};
  },
  components: { Multiselect },
  props: {
    availabilitySetting: Object,
    sessionSupportedEvents: Array,
    userID: String,
    calendarID: String,
  },
  methods: {
    setAvailability() {
      if (
        !this.availabilitySetting.startTime ||
        !this.availabilitySetting.endTime ||
        !this.availabilitySetting.selectedCamps
      ) {
        swal(
          "Details Incomplete",
          "Please fill out all form fields and try again",
          "error"
        );
        return;
      }

      if (
        this.availabilitySetting.startTime >= this.availabilitySetting.endTime
      ) {
        swal(
          "Time Mismatch",
          "Please make sure the start time is before the end time",
          "error"
        );
        return;
      }

      if (
        this.availabilitySetting.repeating &&
        !this.availabilitySetting.repeatingSetting
      ) {
        swal(
          "Repeating Option Missing",
          "Please select a period of repeating availability",
          "error"
        );
        return;
      }

      swal("Loading...", "", "info");

      let url = process.env.VUE_APP_DB_URL + "scheduling/addAvailability";
      axios
        .post(
          url,
          {
            availabilitySetting: this.availabilitySetting,
            userID: this.userID,
            calendarID: this.calendarID,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          this.$emit("addedAvailability", {
            allAvailability: response.data.availability,
          });
          document.getElementById("addAvailabilityClose").click();
          swal("Success", "Availability has been Set", "success");
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
  },
  computed: {
    getAvailabilityDate() {
      return new Date(this.availabilitySetting.date).toLocaleDateString();
    },
    getSessionOptions() {
      let options = [];
      for (const event of this.sessionSupportedEvents) {
        options.push({
          name: event.campName,
          code: event.campStripeId,
        });
      }
      return options;
    },
  },
};
</script>

<style scoped></style>
