import { render, staticRenderFns } from "./StaticAd.vue?vue&type=template&id=44b55c26&scoped=true&"
import script from "./StaticAd.vue?vue&type=script&lang=js&"
export * from "./StaticAd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b55c26",
  null
  
)

export default component.exports