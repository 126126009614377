var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper container justify-content-center",attrs:{"id":"app"}},[_c('ClientLayout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"container justify-content-center"},[_c('div',{staticClass:"row text-center v-step-3",staticStyle:{"margin-top":"35px"}},[_c('div',{staticClass:"col-md-7"},[_c('b',[_c('h1',{style:({
              marginLeft: _vm.isMobile() ? '21px' : '',
              textAlign: 'right',
            })},[_vm._v(" Ad Creatives "),_c('i',{staticClass:"fa-solid fa-circle-info fa-sm",staticStyle:{"color":"#45487a","cursor":"pointer","margin-left":"3px"},on:{"click":_vm.adCreativesExplanation}})])])]),_c('div',{staticClass:"col-sm-5"},[_c('button',{staticClass:"btn rounded-pill",staticStyle:{"background-color":"#363166","color":"white","float":"right"},on:{"click":function($event){return _vm.newCreative()}}},[_vm._v(" New Creative ")])]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control me-2",staticStyle:{"margin-bottom":"10px","min-width":"80%"},style:({
            marginLeft: _vm.isMobile() ? '21px' : '',
          }),attrs:{"type":"search","placeholder":"Search","aria-label":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})]),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.filteredAdCreatives),function(creative,index){return _c('ul',{key:index,staticClass:"list-group"},[(index % 3 == 0)?_c('AdCreativeCard',{staticClass:"grow",attrs:{"id":creative._id,"logo":creative.mediaURL,"title":creative.title,"description":creative.description}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.filteredAdCreatives),function(creative,index){return _c('ul',{key:index,staticClass:"list-group"},[(index % 3 == 1)?_c('AdCreativeCard',{staticClass:"grow",attrs:{"id":creative._id,"logo":creative.mediaURL,"title":creative.title,"description":creative.description}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          marginLeft: _vm.isMobile() ? '21px' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.filteredAdCreatives),function(creative,index){return _c('ul',{key:index,staticClass:"list-group"},[(index % 3 == 2)?_c('AdCreativeCard',{staticClass:"grow",attrs:{"id":creative._id,"logo":creative.mediaURL,"title":creative.title,"description":creative.description}}):_vm._e()],1)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }