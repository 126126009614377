var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ container: !_vm.isMobile },attrs:{"id":"app"}},[_c('div',{staticClass:"card-form"},[_c('div',{staticClass:"card-form__inner"},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.campLogo}}),_c('h3',{staticClass:"text-center"},[_vm._v("Finalize Registration")]),_c('br'),_c('ul',{staticClass:"list-group",staticStyle:{"margin-top":"20px"}},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b',[_vm._v(_vm._s(this.campName))])])]),_vm._m(0)]),_c('PayPalSubscription',{staticClass:"mt-3",attrs:{"planID":_vm.freeTrialPlanID,"camperInfo":this.camperInfo,"additionalCampers":this.additionalCampers,"campId":this.campId},on:{"checkedOut":this.checkedOut}}),_c('p',{staticClass:"text-sm text-muted fst-italic text-center"},[_vm._v(" You must authorize permissions for your athlete profile to continue ")]),_c('br'),_c('br'),_c('br'),_c('button',{class:{
          'card-form__button': true,
          'w-25': !_vm.isMobile,
          'w-50': _vm.isMobile,
          'rounded-pill': true,
        },style:({
          backgroundColor: _vm.secondaryColor,
          color: 'white',
        }),on:{"click":_vm.back}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_vm._v(" Back ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Authorize Athlete Profile")]),_c('div',[_vm._v("$0.00")])])])}]

export { render, staticRenderFns }