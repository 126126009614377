<script setup></script>

<template>
  <div
    class="information-card"
    @click="buySubscription(subscription)"
    v-bind:style="{
      maxWidth: isMobile ? '90%' : '275px',
      cursor: 'pointer',
    }"
  >
    <h5 class="card-title text-start">
      <span class="badge actionFeature"
        >{{ subscription.subscriptionName }} - Enterprise</span
      >
      <br />
      <span class="text-muted" style="font-size: 15px"></span>
    </h5>
    <br />

    <h5 class="card-body">
      <label v-if="this.yearly" style="margin-left: 33px; font-size: 15px"
        ><s>${{ getFakeYearly }}</s></label
      ><br v-if="this.yearly" />
      <span
        ><i class="fa-solid fa-trophy fa-xl m-1" style="color: #363166"></i>
        <label style="margin-right: 3px; cursor: pointer">
          ${{
            this.yearly ? getYearly + " / year" : getMonthly + " / month"
          }}</label
        >
      </span>
    </h5>
    <p class>Everything in the All Star, Plus...</p>
    <br />

    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      24/7 Ad Campaign Capabilities
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      Custom AI Ad Creation <span class="badge actionFeature">New!</span>
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      Traffic-Driving Website Ads
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      New Customer Outreach
    </p>
    <p>
      <i class="fa-solid fa-square-check" style="color: #19d412"></i>
      200 Ad Credits on Sign Up!
    </p>

    <div class="card-footer" style="background-color: #a39ed0"></div>
    <br v-if="promo && !yearly" />
    <button
      class="card-form__button grow"
      id="regularSignInBtn"
      @click="buySubscription(subscription)"
    >
      Get Started Today!
    </button>
  </div>
</template>

<script>
import axios from "axios";
import numeral from "numeral";

export default {
  props: {
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    promo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: "",
      },
    };
  },
  methods: {
    buySubscription(plan) {
      this.$emit("buySubscription", plan);
    },
    getSubscription() {
      //get rookie subscription via axios post request with auth
      let url =
        process.env.VUE_APP_DB_URL +
        "subscription/getSubscriptionByName?name=Hall of Fame";
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.subscription = response.data.subscription;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format("0,0.00");
    },

    getYearly() {
      return numeral(this.subscription.yearlyPrice).format("0,0.00");
    },

    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format("0,0.00");
    },
  },

  mounted() {
    this.getSubscription();
  },
};
</script>
<style scoped>
.actionFeature {
  background-color: #a39ed0;
}
.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}
</style>
