import { render, staticRenderFns } from "./VideoTour.vue?vue&type=template&id=2e0df589&scoped=true&"
import script from "./VideoTour.vue?vue&type=script&lang=js&"
export * from "./VideoTour.vue?vue&type=script&lang=js&"
import style0 from "./VideoTour.vue?vue&type=style&index=0&id=2e0df589&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0df589",
  null
  
)

export default component.exports