var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-6",style:({
      width: _vm.isMobile() ? '99%' : '',
      marginLeft: _vm.isMobile() ? '21px' : '',
      textAlign: _vm.isMobile() ? 'center' : '',
    })},_vm._l((this.resultQuery),function(event,index){return _c('ul',{key:event._id,staticClass:"list-group"},[(index % 3 == 0)?_c('EventListCard',{staticClass:"grow",attrs:{"date":event.date,"id":event.id,"type":event.type,"logo":event.logo,"title":event.name}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
      width: _vm.isMobile() ? '99%' : '',
      marginLeft: _vm.isMobile() ? '21px' : '',
      textAlign: _vm.isMobile() ? 'center' : '',
    })},_vm._l((this.resultQuery),function(event,index){return _c('ul',{key:event._id,staticClass:"list-group"},[(index % 3 == 1)?_c('EventListCard',{staticClass:"grow",attrs:{"date":event.date,"id":event.id,"type":event.type,"logo":event.logo,"title":event.name}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
      width: _vm.isMobile() ? '99%' : '',
      marginLeft: _vm.isMobile() ? '21px' : '',
      textAlign: _vm.isMobile() ? 'center' : '',
    })},_vm._l((this.resultQuery),function(event,index){return _c('ul',{key:event._id,staticClass:"list-group"},[(index % 3 == 2)?_c('EventListCard',{staticClass:"grow",attrs:{"date":event.date,"id":event.id,"type":event.type,"logo":event.logo,"title":event.name}}):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }